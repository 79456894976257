// colors
// https://ux.redhat.com/foundations/color/
$color-primary-100: #ee0000;
$color-primary-200: #d40000;
$color-secondary-100: #f0f0f0;
$color-secondary-200: #f5f5f5;
$color-secondary-300: #d2d2d2;
$color-secondary-400: #b8bbbe;

$color-ternary-100: #151515;
$color-ternary-200: #212427;
$color-ternary-300: #3c3f42;
$color-ternary-400: #6a6e73;
$color-ternary-400: #8a8d90;

$color-text-100: #151515;
$color-text-200: #6a6e73;
$color-text-300: #d2d2d2;

$color-nero: #151515;
$color-nero-1: #141414;
$color-nero-2: #1a1a1a;
$color-raven: #6a6e73;
$color-baltic-sea: #393f44;
$color-white-smoke: #f0f0f0;
$color-navy-blue: #0066cc;
$color-arsenic: #41474d;
$color-aluminum: #808489;
$color-snow: #fafafa;
$ui-01: #f4f4f4;
$ui-02: #ffffff;
$ui-03: #e0e0e0;
$ui-04: #8d8d8d;
$ui-05: #161616;
$success-color-green: var(--pf-v5-global--success-color--100);
$box-shadow-lg-right: rgba(3, 3, 3, 0.07) 12px 0px 10px -4px;

$breakpoint--xs: 0px;
$breakpoint--sm: 576px;
$breakpoint--md: 768px;
$breakpoint--lg: 992px;
$breakpoint--xl: 1200px;
$breakpoint--2xl: 1450px;
$breakpoints: (
    "xs": $breakpoint--xs,
    "sm": $breakpoint--sm,
    "md": $breakpoint--md,
    "lg": $breakpoint--lg,
    "xl": $breakpoint--xl,
    "2xl": $breakpoint--2xl
);
