@import '../../styles/base';

$heights: (25: 25, 50: 50, 75: 75, 100: 100);

.pf-l-bullseye {
    &.pf-m-height {
        @include height-modifiers;
    }
    &.pf-m-text-align-center {
        text-align: center;
    }
}
