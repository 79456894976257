@import "./styles/base";

html {
    font-family: var(--pf-v5-global--FontFamily--redhatfont--sans-serif);
}

a {
    text-decoration: none;
}

h1,
h2,
h3 {
    font-family: var(--pf-v5-global--FontFamily--redhatfont--heading--sans-serif);
}

input,
textarea {
    font-family: var(--pf-v5-global--FontFamily--redhatfont--sans-serif);
}

button {
    font-family: var(--pf-v5-global--FontFamily--redhatfont--sans-serif);
}

.app {
    background-color: var(--pf-v5-global--BackgroundColor--200);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main {
    height: 100vh;
    position: relative;
    z-index: 90;
}

.pf-v5-c-tab-content,
.pf-v5-c-drawer__body {
    background-color: var(--pf-v5-global--BackgroundColor--200);
}

.bg-light {
    background-color: #fff;
}

.page-header-title {
    font-size: 24px;
    color: $color-nero-1;
}

.add-new-button {
    justify-self: flex-start;
}

.strong {
    font-weight: bold;
}

.pf-v5-c-sidebar {
    display: flex;
    flex: 1;

    .pf-v5-c-sidebar__main {
        align-items: stretch;
        width: 100%;

        .pf-v5-c-sidebar__panel {
            background-color: #fff;
            box-shadow: $box-shadow-lg-right;
        }
    }
}

.card {
    background-color: #ffffff !important;
    // min-height: 257px;
    height: 100%;

    .card-header {
        display: flex;
        justify-content: space-between;
        font-size: var(--pf-v5-global--FontSize--lg);
        padding: 1.5rem 1.5rem 1.375rem;

        .card-header--title {
            line-height: 1.3;
            font-weight: normal;
        }
    }

    .card-body--title {
        font-size: var(--pf-v5-global--FontSize--md);
        line-height: 1.5;
        font-weight: 500;
    }

    .card-body--content {
        font-size: var(--pf-v5-global--FontSize-sm);
        line-height: 1.5;
        font-weight: normal;
        word-break: break-all;
    }
}
