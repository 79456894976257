@mixin height-modifiers {

    @each $name,
    $hex in $heights {
        &-#{$name}-vh {
            height: $hex * 1vh;
            max-height: $hex * 1vh;

        }

        &-#{$name}-rem {
            height: $hex * 1rem;
            max-height: $hex * 1rem;

        }
    }
}