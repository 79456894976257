:root {
  --rh-font-size-body-text-md: 1rem;
  --rh-space-lg: 1rem;
  --rh-space-xl: 2rem;
  --rh-border-width-lg: 3px;
}

h3[slot="header"] {
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  a[slot="link"] {
    font-weight: 700;
  }
}

@media screen and (min-width: 992px) {
  rh-navigation-secondary-menu {
    --rh-navigation-secondary-menu-section-grid: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  rh-navigation-secondary-menu {
    --rh-navigation-secondary-menu-section-grid: repeat(4, 1fr);
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pf-v5-c-table__tree-view-title-cell,
.pf-v5-c-table.pf-m-compact tr:not(.pf-v5-c-table__expandable-row) > *:last-child {
  vertical-align: middle !important;
}

.pf-v5-c-table__tree-view-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .pf-v5-c-table__tree-view-icon {
    display: flex;
  }
}
