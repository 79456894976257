@import "../../styles/base";

$heights: (
    25: 25,
    50: 50,
    75: 75,
    100: 100
);

.pf-l-bullseye {
    &.pf-m-height {
        @include height-modifiers;
    }
}

.pf-l-flex {
    &.pf-m-gap-sm {
        gap: 0.5rem;
    }
}

.pf-v5-u-font-weight-light {
    --pf-v5-c-content--h2--FontWeight: var(--pf-v5-global--FontWeight--light);
}
